import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Improved Station Image Downloader</AnchorLink>
    </AnchorLinks>
    <h2>{`Improved Station Image Downloader`}</h2>
    <ul>
      <li parentName="ul">{`Allows to pick which images to be downloaded.`}</li>
      <li parentName="ul">{`If the image already exists on the server, it computes MD5 hash and verifies it against the original one, if both match, skips the download.`}</li>
      <li parentName="ul">{`Allows the user to specify a partition on the server to which the images will be downloaded.`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://github-rd.carefusion.com/pages/devops/provision-documentation/releases/v2020.12.15/EnhancedImageDownloaderScreenshot.png",
        "alt": "images"
      }}></img>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.09722222222221%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQ4y5VSy07DQAzMl/MHfAUSZ76AGyeuXFGlqhWQh5psstn3Dho3G9pS8bA0Srxrj8f2VvvNKzb7GruPDtv3Dt1hwDRNGMfxR6hxhNFa8m4fnnFz/4S7xxdUXduiPxyglJJA59wZLGHtl28trD25tw6TnjFOM/RsUG13b2jaDk3TCJyzAu+9IBAhwHt39ENACH69P555xOW8Ius8z0g5g0ZF9IuFmBBShpo0ejXCxwQXInLOgqKe6SklVGGtEjFMBuNskWJEjFECfAhwPsg9S7JwShmnlhcxQlhmQZJh6DEpheg9+r4XWGNgDLvQsizG0ViQPsUUE0IeCGFKEsBkVtRao21bOZNRWIuu62SGNOYwlnmlALES0uGWOT8SMlieh1JCxjgWISFJ+GVOmeU3wqKwLIT/dV3L5klKgmEYZAzFj0v7WeaafickAX0qZHDZKGMLisLinxGypdIyl0DC05bpsxDv/9SyqDEGMSUYF9anc6mi+Je4umVRuLy3/xpJz7Z8bUZF5eX/NbDlT2nHRZp24VKtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "images",
          "title": "images",
          "src": "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png",
          "srcSet": ["/static/30106965782055027235bec2624a7c43/7fc1e/workflows.png 288w", "/static/30106965782055027235bec2624a7c43/a5df1/workflows.png 576w", "/static/30106965782055027235bec2624a7c43/3cbba/workflows.png 1152w", "/static/30106965782055027235bec2624a7c43/9d3ac/workflows.png 1633w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </GifPlayer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      